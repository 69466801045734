
import Error from "./partials/Error";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import Intro from "./partials/Intro";
import Layout from "./partials/Layout";
import Navigation from "./partials/Navigation";
import PageContext from "./partials/Page";
import SEO from "./partials/Seo";
import Widgets from "./partials/Widgets";

export {
  Error,
  Footer,
  Header,
  Intro,
  Layout,
  Navigation,
  PageContext,
  SEO,
  Widgets,
}
