
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { Linear, Power4, TimelineMax, TweenMax } from "gsap";

import { Layout } from "..";

import sc from "./ComponentTemplate.module.scss";
import sh from "./Header.module.scss";
import sl from "./Layout.module.scss";

const defaultState = {
  delayedRedirect: () => {},
  isColorTheme: "light",
  isCurrentRoute: "",
  isFooterExpanded: false,
  isSearchExpanded: false,
  isUiMinimized: false,
  toggleColorTheme: () => {},
  toggleCurrentRoute: () => {},
  toggleFooter: () => {},
  toggleSearch: () => {},
  toggleUi: () => {},
};

const PageContext = React.createContext(defaultState);

const PageProvider = ({ children }) => {

  const [isColorTheme, setColorTheme] = useState("light");
  const [isCurrentRoute, setCurrentRoute] = useState("");
  const [isFooterExpanded, setFooterExpanded] = useState(false);
  const [isSearchExpanded, setSearchExpanded] = useState(false);
  const [isUiMinimized, setUiMinimized] = useState(false);

  const getStorage = (key) => {
    return localStorage.getItem("dglgr") && JSON.parse(localStorage.getItem("dglgr"))[key];
  };

  const setStorage = (key) => {
    localStorage.setItem("dglgr", JSON.stringify({ ...JSON.parse(localStorage.getItem("dglgr")), ...key }));
  };

  // covering up the component + delayed redirect
  const delayedRedirect = (event) => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute("href");

    TweenMax.set(`.${sc.cover}`, { clearProps: "all" });
    TweenMax.set(`.${sc.cover} > div`, { x: "-100%" });
    TweenMax.to(`.${sc.cover} > div`, .3, {
      ease: Power4.easeIn,
      onComplete: () => navigate(url),
      x: "0%",
    });
  }

  const toggleColorTheme = (theme) => {
    setStorage({ colorTheme: theme });
    setColorTheme(theme);
  };

  const toggleCurrentRoute = (route) => {
    setStorage({ currentRoute: route });
    setCurrentRoute(route);
  };
 
  const toggleFooter = () => {
    setStorage({ footerExpanded: !isFooterExpanded });
    setFooterExpanded(!isFooterExpanded);
  };

  const toggleSearch = () => {
    setStorage({ searchExpanded: !isSearchExpanded });
    setSearchExpanded(!isSearchExpanded);
  };

  const toggleUi = () => {
    setStorage({ uiMinimized: !isUiMinimized });
    setUiMinimized(!isUiMinimized);

    const el = {
      content: document.querySelector(`.${sl.content}`),
      ui: document.querySelector(`.${sl.ui}`),
      uiToggle: document.querySelector(`.${sl.ui__toggle}`),
    }

    const uiAnimation = new TimelineMax();

    if (!isUiMinimized) {
      // minimize ui
      uiAnimation
        .clear()
        .to(el.content, .1, { ease: Linear.easeNone, paddingLeft: 202 * 1.05 }, 0)
        .to(el.content, .5, { ease: Power4.easeInOut, paddingLeft: 0 }, .1)
        .set(el.ui, { scale: 1 }, 0)
        .to(el.ui, .1, { ease: Linear.easeNone, scale: 1.05 }, 0)
        .to(el.ui, .5, { ease: Power4.easeInOut, scale: 0 }, .1)
        .set(el.uiToggle, { scale: .01 }, 0)
        .to(el.uiToggle, .5, { ease: Power4.easeInOut, scale: 1 }, .2);
    } else {
      // maximize ui
      uiAnimation
        .clear()
        .set(el.uiToggle, { scale: 1 }, 0)
        .to(el.uiToggle, .5, { clearProps: "all", ease: Power4.easeInOut, scale: 0 }, 0)
        .set(el.ui, { scale: 0 }, 0)
        .to(el.ui, .5, { ease: Power4.easeIn, scale: 1.05 }, 0)
        .to(el.ui, .3, { clearProps: "all", ease: Power4.easeOut, scale: 1 }, .5)
        .to(el.content, .5, { ease: Power4.easeIn, paddingLeft: 202 * 1.05 }, 0)
        .to(el.content, .3, { clearProps: "padding", ease: Power4.easeOut, paddingLeft: 202 }, .5);
    }
  };

  useEffect(() => {
    if (getStorage("colorTheme")) setColorTheme(getStorage("colorTheme"));
    if (getStorage("currentRoute")) setCurrentRoute(getStorage("currentRoute"));
    if (getStorage("footerExpanded")) setFooterExpanded(getStorage("footerExpanded"));
    if (getStorage("searchExpanded")) setSearchExpanded(getStorage("searchExpanded"));
    if (getStorage("uiMinimized")) setUiMinimized(getStorage("uiMinimized"));

    // extra treatment for initial page load
    if (getStorage("footerExpanded")) TweenMax.set("html", { overflow: "hidden" });
    if (getStorage("searchExpanded")) {
      TweenMax.set("html", { overflow: "hidden" });
      TweenMax.set([document.querySelector(`.${sh.header}`), document.querySelector(`.${sh.scrollable}`)], { delay: .2, width: "110%" }); // temp fix for overflow: hidden & position fixed - search not showing well on mobile - TODO figure out better way later
    }
    if (getStorage("uiMinimized")) TweenMax.set(document.querySelector(`.${sl.content}`), { paddingLeft: 0 });

  }, []);

  return (
    <PageContext.Provider value={{
      delayedRedirect,
      isColorTheme,
      isCurrentRoute,
      isFooterExpanded,
      isSearchExpanded,
      isUiMinimized,
      toggleColorTheme,
      toggleCurrentRoute,
      toggleFooter,
      toggleSearch,
      toggleUi,
    }}>
      <div /> {/* reach router hack to disable extra <div> wrapper created on build */}
      <Layout>
        {children}
      </Layout>
    </PageContext.Provider>
  );
};

PageProvider.propTypes = {
  children: PropTypes.element,
};

export default PageContext;
export { PageProvider };
