
import React from "react";
import PropTypes from "prop-types";

import s from "./Error.module.scss";

const Error = ({ children }) => (
  <div className={s.error}>
    <p>
      {children}
    </p>
  </div>
);

Error.propTypes = {
  children: PropTypes.any,
};

export default Error;
