
import React from "react";
import Particles from "react-particles-js";

import s from "./Intro.module.scss";

const Intro = () => (
  <div className={s.intro}>
    {/* [intro + snow particles + lego figure snowboarding holding a laptop + big arrow pointing to nav + what&apos;s new & release version] */}
    <div className={s.text}> 
      <p>This website uses cookies, css, some js, snow, lorem ipsum dolor & is currently in public βetα mode (v1.1.0).</p>
      <p>← Use that nav and widgets to check out the content & enjoy!</p>
    </div>
    <Particles
      className={s.particles}
      params={{
        particles: {
          number: {
            value: 178,
            density: {
              enable: true,
              value_area: 900,
            },
          },
          color: {
            value: "#fff",
          },
          shape: {
            type: "circle",
            // type: "polygon",
            // polygon: {
            //   nb_sides: 6,
            // },
            // stroke: {
            //   width: 1,
            //   color: "#ccc",
            // },
            // type: "image",
            // image: {
            //   // src: "http://www.dynamicdigital.us/wp-content/uploads/2013/02/starburst_white_300_drop_2.png",
            //   // src: "../../images/cookie-x.png",
            //   // width: 80,
            //   // height: 70,
            //   src: `<svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            //   <path d="M5.95063 1.58745L5.95059 1.58749L5.95579 1.59254C6.46087 2.08319 6.76714 2.77929 6.76714 3.53813C6.76714 3.80465 6.5956 4.15806 6.26532 4.5744C5.99941 4.90961 5.68694 5.21863 5.41267 5.48986C5.35836 5.54357 5.30555 5.59579 5.2549 5.64645L5.25486 5.64641L5.24981 5.65161C4.75915 6.15669 4.06306 6.46296 3.30422 6.46296C2.55131 6.46296 1.85074 6.14365 1.35354 5.64645C1.20704 5.49995 1.1414 5.28115 1.14707 4.89242C1.14975 4.70819 1.16749 4.51316 1.18761 4.29201C1.18848 4.2824 1.18936 4.27275 1.19024 4.26304C1.21067 4.03812 1.23275 3.78753 1.23275 3.53813C1.23275 2.78523 1.55207 2.08465 2.04927 1.58745L2.04931 1.58749L2.05435 1.58229C2.28837 1.3414 2.70585 1.0646 3.18766 0.846903C3.67142 0.628326 4.15235 0.5 4.5 0.5C4.79911 0.5 4.99063 0.59866 5.18694 0.77226C5.29473 0.867578 5.40023 0.982709 5.52702 1.12591C5.54296 1.1439 5.55925 1.16237 5.5759 1.18124C5.68614 1.30619 5.81189 1.44872 5.95063 1.58745Z" fill="white" stroke="black"/>
            //   </svg>
            //   `,
            // },
          },
          opacity: {
            value: 1,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 9,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "bottom",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: true,
              rotateX: 1234,
              rotateY: 1234,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "repulse",
            },
            onclick: {
              enable: false,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 575,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 539,
              size: 291,
              duration: 6,
              opacity: 1,
              speed: 3,
            },
            repulse: {
              distance: 263,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2
            },
          },
        },
        retina_detect: true,
      }}
    />
  </div>
);

export default Intro;
