
// Gatsby's Browser APIs
// https://www.gatsbyjs.org/docs/browser-apis/

import React from "react";
import PropTypes from "prop-types";

import { PageProvider } from "./src/layout/partials/Page";

export const wrapRootElement = ({ element }) => (
  <PageProvider>{element}</PageProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.element,
};
