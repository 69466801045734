
import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TweenMax } from "gsap";
import { throttle } from "throttle-debounce";

import TooSmall from "../../images/too-small.jpg";
import { Footer, Header, PageContext } from "..";
import { DLogo } from "../svgs";

import "../../styles/index.scss";
import sl from "./Layout.module.scss";
import sn from "./Navigation.module.scss";
import sw from "./Widgets.module.scss";

const Layout = ({ children }) => {

  const elContent = useRef(null);
  const elUi = useRef(null);
  const fadeOutEnabled = false;
  const page = useContext(PageContext);

  // ui fade out on mousemove
  useEffect(() => {

    if (!fadeOutEnabled) return () => {};

    const el = {
      activeLink: elUi.current.querySelector(`.${sn.navigation__link____active}`),
      footer: elUi.current.lastElementChild,
      header: elUi.current.firstElementChild,
      links: elUi.current.querySelectorAll(`.${sn.navigation__link}`),
      linkSpacers: elUi.current.querySelectorAll(`.${sn.navigation__spacer} span`),
      logo: elUi.current.querySelector("h1"),
      widgets: elUi.current.querySelector(`.${sw.widgets}`),
    };

    el.active = el.activeLink && {
      prev4: el.activeLink.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.firstElementChild,
      prev3: el.activeLink.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.firstElementChild,
      prev2: el.activeLink.parentElement.previousElementSibling.previousElementSibling.firstElementChild,
      prev1: el.activeLink.parentElement.previousElementSibling.firstElementChild,
      next1: el.activeLink.parentElement.nextElementSibling.firstElementChild,
      next2: el.activeLink.parentElement.nextElementSibling.nextElementSibling.firstElementChild,
      next3: el.activeLink.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.firstElementChild,
      next4: el.activeLink.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.firstElementChild,
    };

    const elements = [el.links, el.linkSpacers, el.logo, el.widgets];

    const resetFadeOuts = () => {
      TweenMax.to([elements, el.footer.firstElementChild], 1, { clearProps: "all", opacity: 1 });
    };

    const setFooterFadeOut = throttle(30, (event) => {
      const x = event.touches ? event.touches[0].clientX : event.clientX;
      const p = x / (window.innerWidth - el.footer.firstElementChild.offsetWidth);
      const pMax = 1;
      const pMin = .15;

      const opacity = p > pMax ? pMax : p < pMin ? pMin : p;

      TweenMax.to(el.footer.firstElementChild, 1.5, {
        opacity: page.isFooterExpanded ? 1 : opacity,
      });
      
      // blur disabled due to performance issues
      // const blur = 1 - p < 0 ? 0 : (1 - p) * 2;
      // TweenMax.set(el.footer.firstElementChild, { filter: `blur(${blur}px)` });
    });

    const setHeaderFadeOut = throttle(30, (event) => {
      const x = event.touches ? event.touches[0].clientX : event.clientX;
      const p = (x - el.header.offsetWidth) / (window.innerWidth - el.header.offsetWidth);
      const pMax = 1;
      const pMin = .15;
      const pMod = 1 - p - p / 1.3;

      const opacity = pMod > pMax ? pMax : pMod < pMin ? pMin : pMod;

      TweenMax.to(elements, 1.5, { opacity });
      
      // blur disabled due to performance issues
      // const blur = p < 0 ? 0 : p * 2;
      // TweenMax.set(elements, { filter: `blur(${blur}px)` });
      
      if (el.activeLink) {
        TweenMax.to([el.active.next1, el.active.prev1], 1.5, { opacity: opacity * 3 >= 1 ? 1 : opacity * 3 });
        TweenMax.to([el.active.next2, el.active.prev2], 1.5, { opacity: opacity * 2.5 >= 1 ? 1 : opacity * 2.5 });
        TweenMax.to([el.active.next3, el.active.prev3], 1.5, { opacity: opacity * 2 >= 1 ? 1 : opacity * 2 });
        TweenMax.to([el.active.next4, el.active.prev4], 1.5, { opacity: opacity * 1.5 >= 1 ? 1 : opacity * 1.5 });
      }
    });

    if (el.activeLink) {
      if (!page.isFooterExpanded) window.addEventListener("mousemove", setFooterFadeOut);
      window.addEventListener("mousemove", setHeaderFadeOut);
    } else {
      resetFadeOuts();
    }

    return () => {
      resetFadeOuts();
      window.removeEventListener("mousemove", setFooterFadeOut);
      window.removeEventListener("mousemove", setHeaderFadeOut);
      setFooterFadeOut.cancel();
      setHeaderFadeOut.cancel();
    };

  }, [page.isCurrentRoute, page.isFooterExpanded]);

  return (
    <div className={sl.layout} data-theme={page.isColorTheme}>
      <div className={classNames(sl.ui, { [sl.ui____minimized]: page.isUiMinimized })} ref={elUi}>
        <Header />
        <Footer />
      </div>
      <div className={sl.ui__toggle} onClick={page.toggleUi}>
        <DLogo />
      </div>
      <main className={sl.content} ref={elContent}>
        {children}
      </main>
      <div className={sl.toosmall}>
        <div>
          <img src={TooSmall} alt="Photography of a man holding an object." />
          <p>Too smalll! Sorry ¯\_(ツ)_/¯</p>
          <p>Please rotate your device or use sligtly wider screen.</p>
          <p>Btw I&#39;ll make this site very mobile friendly really soon, I promise! Meanwhile please browse it horizontally... or on desktop.</p>
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

export default Layout;
