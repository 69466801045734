
import React, { useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import { Linear, Power4, TweenMax } from "gsap";

import { PageContext, SEO } from "..";
// import { CcrchLogo, LoveIcon, PeaceIcon, VettvangurLogo } from "../svgs";
import { CcrchLogo, LoveIcon, PeaceIcon } from "../svgs";

import s from "./Footer.module.scss";

const Footer = () => {

  const elClose = useRef(null);
  const elContent = useRef(null);
  const elHeart = useRef(null);
  const elScrollable = useRef(null);
  const elScrollableScroll = useRef(null);
  const page = useContext(PageContext);
  const smoothScrollEnabled = false;
  
  const getHeartScale = () => {
    TweenMax.set(elHeart.current, { clearProps: "all" });

    const scaleX = window.innerWidth / elHeart.current.getBoundingClientRect().width * 2.5;
    const scaleY = window.innerHeight / elHeart.current.getBoundingClientRect().height * 2.9;
    const scale = Math.max(scaleX, scaleY);

    return scale;
  };

  const hideContent = () => {
    TweenMax.set(elClose.current, {
      pointerEvents: "none",
    });
    TweenMax.to(elClose.current, .1, {
      ease: Linear.easeNone,
      scale: .5,
    });
    TweenMax.to(elClose.current, .5, {
      delay: .1,
      ease: Power4.easeOut,
      opacity: 0,
      scale: 3,
    });
    TweenMax.staggerTo(elScrollable.current.querySelectorAll("h2, p"), .5, {
      delay: .1,
      ease: Power4.easeIn,
      opacity: 0,
      stagger: .05,
      y: -50,
    });
    TweenMax.to(elContent.current, .5, {
      delay: .3,
      opacity: 0,
      onComplete: () => {
        if (!page.isSearchExpanded) TweenMax.set("html", { clearProps: "all" });
        TweenMax.to(elHeart.current, .75, {
          ease: Power4.easeInOut,
          scale: 1,
          onComplete: () => {
            page.toggleFooter();
            TweenMax.set(elContent.current, {
              clearProps: "all",
            });
          },
        });
      },
      onStart: () => {
        TweenMax.set(elHeart.current, {
          scale: getHeartScale,
        });
      },
    });
  };

  const hoverClose = (event) => {
    switch (event.type) {
      case "mouseenter":
        TweenMax.to(elClose.current, 1, {
          ease: Power4.easeOut,
          rotation: 7,
          scale: .9,
        });
        break;
      case "mouseleave":
        TweenMax.to(elClose.current, 1, {
          clearProps: "all",
          ease: Power4.easeOut,
          rotation: 0,
          scale: 1,
        });
        break;
      default: return;
    }
  };
 
  const showContent = () => {
    TweenMax.to(elHeart.current.firstChild, .1, {
      animation: "none",
      ease: Linear.easeNone,
      scale: 5,
      onComplete: () => {
        TweenMax.to(elHeart.current.firstChild, .3, {
          ease: Power4.easeOut,
          scale: 1,
        });
      },
    });
    TweenMax.to(elHeart.current, .75, {
      delay: .29,
      ease: Power4.easeInOut,
      scale: getHeartScale,
      onComplete: () => {
        TweenMax.set("html", { overflow: "hidden" });
        TweenMax.set(elHeart.current.firstChild, { clearProps: "all" });
        // reveal content
        TweenMax.set(elContent.current, { clearProps: "all" });
        TweenMax.staggerTo(elScrollable.current.querySelectorAll("h2, p"), 1.5, { clearProps: "all", ease: Power4.easeOut, opacity: 1, stagger: .1, y: 0 });
        // reveal x button
        const delay = .1 + elScrollable.current.querySelectorAll("h2, p").length / 10;
        TweenMax.to(elClose.current, 2, { clearProps: "all", delay, ease: Power4.easeOut, opacity: 1, rotation: 0 });
        TweenMax.to(elClose.current.firstElementChild.childNodes[0], .5, { clearProps: "all", delay: delay + .2, ease: Power4.easeOut, rotation: 0, scaleY: 1 });
        TweenMax.to(elClose.current.firstElementChild.childNodes[1], .5, { clearProps: "all", delay: delay + .3, ease: Power4.easeOut, rotation: 0, scaleX: 1 });
        TweenMax.to(elClose.current.firstElementChild.childNodes[3], .75, { clearProps: "all", delay: delay + .4, ease: Power4.easeOut, rotation: 0, scaleY: 1 });
        TweenMax.to(elClose.current.firstElementChild.childNodes[2], 1, { clearProps: "all", delay: delay + .5, ease: Power4.easeOut, rotation: 0, scaleX: 1 });
      },
      onStart: () => {
        page.toggleFooter();
        TweenMax.set(elContent.current, { opacity: 0 });
        TweenMax.set(elScrollable.current.querySelectorAll("h2, p"), { opacity: 0, y: 50 });
        TweenMax.set(elClose.current, { rotation: -120, scale: 1 });
        TweenMax.set([elClose.current.firstElementChild.childNodes[0], elClose.current.firstElementChild.childNodes[3]], { rotation: -45, scaleY: 0 });
        TweenMax.set([elClose.current.firstElementChild.childNodes[1], elClose.current.firstElementChild.childNodes[2]], { rotation: -45, scaleX: 0 });
      },
    });
  };

  // handling footer scrollable content
  useEffect(() => {

    if (!smoothScrollEnabled) return () => {};

    const changeOrder = (event) => {
      const y = -(elScrollableScroll.current.firstElementChild.getBoundingClientRect().top - elScrollable.current.parentElement.getBoundingClientRect().top);
      const yMax = Math.floor(elScrollable.current.getBoundingClientRect().height - elScrollable.current.parentElement.getBoundingClientRect().height);

      TweenMax.set(elScrollableScroll.current, {
        zIndex: event ? 3 : 1,
      });

      setTimeout(() => {
        if (y <= 0 || y >= yMax) {
          TweenMax.set(elScrollableScroll.current, {
            zIndex: 1,
          });
        }
      }, 60);
    };

    const handleResize = () => {
      TweenMax.set(elScrollableScroll.current.firstElementChild, {
        height: elScrollable.current.offsetHeight,
      });
    };

    const handleScroll = () => {
      const y = elScrollableScroll.current.firstElementChild.getBoundingClientRect().top - elScrollable.current.parentElement.getBoundingClientRect().top;

      TweenMax.to(elScrollable.current, 1.2, {
        ease: Power4.easeOut,
        onComplete: changeOrder,
        y,
      });

      setTimeout(changeOrder, 600);
    };

    const scrollUp = () => {
      if (elScrollable.current.getBoundingClientRect().top < 0) {
        elScrollableScroll.current.scrollTo(0, 0);
      }
    };

    if (page.isFooterExpanded) {
      handleResize();
      scrollUp();
      elScrollableScroll.current.addEventListener("scroll", handleScroll);
      elScrollable.current.addEventListener("DOMMouseScroll", changeOrder);
      elScrollable.current.addEventListener("mousewheel", changeOrder);
      elScrollable.current.addEventListener("touchmove", changeOrder);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      elScrollableScroll.current.removeEventListener("scroll", handleScroll);
      elScrollable.current.removeEventListener("DOMMouseScroll", changeOrder);
      elScrollable.current.removeEventListener("mousewheel", changeOrder);
      elScrollable.current.removeEventListener("touchmove", changeOrder);
      window.removeEventListener("resize", handleResize);
    };

  }, [page.isFooterExpanded]);

  return (
    <footer className={s.footer}>
      <div className={s.trigger}>
        <span onClick={showContent}>
          Made with <i><PeaceIcon /></i> & <i ref={elHeart}><LoveIcon /></i> in Iceland. Powered by
        </span>
        <a href="http://ccrch.com" rel="noopener noreferrer" target="_blank">
          <CcrchLogo />
        </a>
        {/* <span onClick={showContent}>
          &
        </span>
        <a href="http://vettvangur.is" rel="noopener noreferrer" target="_blank">
          <VettvangurLogo />
        </a> */}
      </div>
      {page.isFooterExpanded && <SEO title="Info" />}
      <div className={classNames(s.content, { [s.content____expanded]: page.isFooterExpanded, [s.content____smoothscroll_disabled]: !smoothScrollEnabled })} ref={elContent}>
        <button className={s.close} onClick={hideContent} onMouseEnter={hoverClose} onMouseLeave={hoverClose} ref={elClose}>
          <div>
            <div />
            <div />
            <div />
            <div />
          </div>
          <span>Close</span>
        </button>
        <div className={s.scrollable} ref={elScrollable}>
          <section className={s.content__definition}>
            <h2>duglegur</h2>
            <p>/ˈtʏɣ.lɛːɣʏr/</p>
            <p>1. adj. diligent, working hard or skilfully</p>
            <p>2. n. digital playground made with love for coding</p>
          </section>
          <section>
            <p>This site is currently in public βetα mode (v1.1.0). Lot of improvements & updates will be coming in shortly. List of to-dos is already quite long, but if you want to keep me busy, spot a bug or have an idea on how to improve something please, by all means, <a href="mailto:mike@duglegur.is">drop me a line</a>.</p>
            <p>Most of the stuff you find here is already available @ <a href="http://codepen.io/ccrch" target="_blank" rel="noopener noreferrer">codepen.io/ccrch</a>, not all of it but the plan is to have it all open source.</p>
            <p>Also, the site isn&#39;t really mobile & a11y friendly yet but I&#39;m working hard to fix it. Hopefully it&#39;ll happen in next few weeks. Stay tuned.</p>
            <p>Peace out, Mike</p>
            <p className={s.content__date}>duglegur.is@1.1.0/30.01.2020/23.56.07</p>
          </section>
          {/* <p>My name is Mike and this site is my digital playground. I&#39;m a Front End Developer & Web Designer with almost twenty years of experience in digital media. I live in Reykjavík, Iceland.</p> */}
          {/* <p>I created this site because I&#39;m very passionate about coding & really like to share my ideas with all of you, to make internet nicer & better.</p> */}
          {/* <p>My name is Mike and this site is my digital playground. I&#39;m a Front End Developer & Web Designer with almost twenty years of experience in digital media. I live in Reykjavík, Iceland. I do websites...</p> */}
          {/* <p>[that&#39;s who i am + lego, snow, coding]</p>
          <p>[release versions + disclaimer]</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam laoreet, augue eu commodo egestas.</p>
          <p>Ut eu odio malesuada, porta justo vitae, facilisis ante. Cras velit odio, sodales aliquet pulvinar id, ullamcorper vel risus. Etiam cursus velit ex. Sed semper, lacus vel dapibus feugiat, quam odio efficitur dolor, vitae venenatis leo nisi vitae ipsum. Donec fringilla lacinia turpis, eget egestas odio malesuada ut. Praesent in nulla ornare, rutrum est eu, sollicitudin diam. Duis fermentum libero nec urna faucibus gravida. Fusce semper mauris sed enim elementum, non aliquam tellus iaculis. Maecenas lacus eros, sollicitudin at dapibus vitae, congue sed nisl. Nullam convallis, nulla quis finibus faucibus, arcu ante ornare metus, sit amet sollicitudin turpis risus nec ligula. Morbi auctor ligula et nisi vestibulum volutpat.</p>
          <p>Tortor ante pellentesque tellus, ac eleifend quam sapien at erat.</p> */}
        </div>
        <div className={s.scrollable__scroll} ref={elScrollableScroll}>
          <div />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// TODO - text posted to svef

// Digital playground with bunch of code/css/js/visual experiments.

// Designers/developers/students

// Idea is to have the whole thing open source, available for re-use to anyone who'd like to use a bit of code in his/hers project. Most of the stuff is already published @ https://codepen.io/ccrch. Current version of the site is still in beta & there's quite a lot of features I'm still working on to make it better. Also there's way more experiments/components that I'm going to upload gradually - as for today website contains only about 20% of all the stuff I'd like to publish.
// I'll be also updating all the codepen bits as while working on duglegur.is, on the older components, I improved most of them. :)
// Unfortunatelly the site isn't really mobile & a11y friendly yet but I'm working hard to fix it :) Hopefully it'll happen in next few weeks.