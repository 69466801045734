
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { Power4, TimelineMax, TweenMax } from "gsap";

import { PageContext } from "..";
import { DuglegurLogo } from "../svgs";

import Data from "../../data/components.json";

import sn from "./Navigation.module.scss";

const Navigation = () => {

  const elNavigation = useRef(null);
  const page = useContext(PageContext);

  // triggering active navigation link on page route change
  useEffect(() => {

    const getHeight = (element) => {
      let height = undefined;
      TweenMax.set(element, { height: "auto" });
      height = element.offsetHeight;
      TweenMax.set(element, { clearProps: "all" });
      return height;
    };

    const toggleLink = () => {
      const activeLink = elNavigation.current.querySelector(`.${sn.navigation__link____active}`);
      const activeInfo = activeLink && activeLink.nextElementSibling;
      const activeInfoHeight = activeInfo && getHeight(activeInfo);

      const expandedInfo = elNavigation.current.querySelector(`.${sn.navigation__info____expanded}`);

      const collapseAnimation = new TimelineMax();
      const expandAnimation = new TimelineMax();

      if (expandedInfo) {
        expandedInfo.classList.remove(sn.navigation__info____expanded);
        collapseAnimation
          .to(expandedInfo.previousElementSibling, .4, { ease: Power4.easeOut, height: 22 }, 0)
          .to(expandedInfo.previousElementSibling.firstElementChild , .4, { ease: Power4.easeOut, opacity: expandedInfo.previousElementSibling.classList.contains(sn.navigation__link____hidden) ? .5 : 1 }, 0)
          .to(expandedInfo, .4, { ease: Power4.easeOut, height: 0, opacity: 0 }, 0);
      }
      
      if (activeInfo) {
        activeInfo.classList.add(sn.navigation__info____expanded);
        expandAnimation
          .to(activeLink, .4, { ease: Power4.easeOut, height: 0 }, 0)
          .to(activeLink.firstElementChild, .4, { ease: Power4.easeOut, opacity: 0 }, 0)
          .fromTo(activeInfo, .4, { height: 0, opacity: 0, y: -22 }, { ease: Power4.easeOut, height: activeInfoHeight, opacity: 1, y: 0 }, 0)
          .fromTo(activeInfo.querySelector("h2"), .7, { opacity: 0, x: "-50%", y: "15%" }, { clearProps: "all", ease: Power4.easeOut, opacity: 1, x: "2%", y: "0%" }, 0)
          .fromTo(activeInfo.querySelector("h2 + div"), .85, { opacity: 0, x: "-50%" }, { clearProps: "all", ease: Power4.easeOut, opacity: 1, x: "-1%" }, .05)
          .fromTo(activeInfo.querySelector("h2 + div + span"), 1, { opacity: 0, x: "-50%" }, { clearProps: "all", ease: Power4.easeOut, opacity: 1, x: "-2%" }, .1);
      }
    };
    
    toggleLink();

  }, [page.isCurrentRoute]);

  return (
    <nav className={sn.navigation} ref={elNavigation}>
      <h1 className={sn.logo}>
        <Link
         activeClassName={sn.logo__link____active}
         className={sn.logo__link}
         onClick={page.delayedRedirect}
         to="/"
        >
          <DuglegurLogo />
          Duglegur
        </Link>
      </h1>
      <ul className={sn.navigation__list}>
        <li className={sn.navigation__spacer}><span /></li>
        <li className={sn.navigation__spacer}><span /></li>
        <li className={sn.navigation__spacer}><span /></li>
        <li className={sn.navigation__spacer}><span /></li>
        {Data
          // TODO - decide later whether or not show hidden components
          // .filter((component) => process.env.mode === "dev" ? component : !component.hidden)
          .map((component) => (
            <li key={`${component.id}-link`}>
              <Link
                activeClassName={sn.navigation__link____active}
                className={classNames(sn.navigation__link, { [sn.navigation__link____hidden]: component.hidden })}
                onClick={page.delayedRedirect}
                to={`/${component.id}/`}
              >
                <span>
                  <em>
                    {component.name}
                  </em>
                </span>
              </Link>
              <div className={sn.navigation__info}>
                <div>
                  <h2>{component.name}</h2>
                  <div dangerouslySetInnerHTML={{__html: component.description}} />
                  <span>{component.date}</span>
                </div>
              </div>
            </li>
          ))
        }
        <li className={sn.navigation__spacer}><span /></li>
        <li className={sn.navigation__spacer}><span /></li>
        <li className={sn.navigation__spacer}><span /></li>
        <li className={sn.navigation__spacer}><span /></li>
      </ul>
    </nav>
  );
};

export default Navigation;
