
import React, { useContext, useRef, useState } from "react";
import classNames from "classnames";
import { Linear, Power4, TweenMax } from "gsap";
import { Link } from "gatsby";
import Fuse from "fuse.js";

import Data from "../../data/components.json";
import { PageContext, SEO } from "..";
import { MinimizeIcon, SearchIcon, SortIcon, ThemeIcon } from "../svgs";

import s from "./Widgets.module.scss";
import sh from "./Header.module.scss";

const Widgets = () => {

  const isSortingEnabled = false;

  const elSearch = useRef(null);
  const elSearchClose = useRef(null);
  const elSearchContent = useRef(null);
  const elSearchCover = useRef(null);
  const elSearchInput = useRef(null);
  const page = useContext(PageContext);

  const [searchResults, setsearchResults] = useState(null);
  const fuzzySearch = new Fuse(Data, {
    shouldSort: true,
    // includeMatches: true,
    includeScore: true,
    findAllMatches: true,
    threshold: 0.5,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: [
      "name",
      "description",
    ],
  });

  const hideSearch = () => {
    TweenMax.set(elSearchClose.current, {
      pointerEvents: "none",
    });
    TweenMax.to(elSearchClose.current, .1, {
      ease: Linear.easeNone,
      scale: .5,
    });
    TweenMax.to(elSearchClose.current, .5, {
      clearProps: "all",
      delay: .1,
      ease: Power4.easeOut,
      opacity: 0,
      scale: 3,
    });
    TweenMax.fromTo(elSearchCover.current, .3, {
      x: "-100%",
    }, {
      delay: .2,
      ease: Power4.easeIn,
      x: "0%",
      onComplete: () => {
        TweenMax.set("html", { clearProps: "all" });
        TweenMax.set([document.querySelector(`.${sh.header}`), document.querySelector(`.${sh.scrollable}`)], { delay: .1, clearProps: "all" }); // temp fix for overflow: hidden & position fixed - search not showing well on mobile - TODO figure out better way later
        TweenMax.to(elSearch.current, .3, {
          clearProps: "all",
          ease: Power4.easeInOut,
          x: "100%",
          onComplete: page.toggleSearch,
        });
      },
    });
  };

  const hoverSearchClose = (event) => {
    switch (event.type) {
      case "mouseenter":
        TweenMax.to(elSearchClose.current, 1, {
          ease: Power4.easeOut,
          rotation: 7,
          scale: .9,
        });
        break;
      case "mouseleave":
        TweenMax.to(elSearchClose.current, 1, {
          clearProps: "all",
          ease: Power4.easeOut,
          rotation: 0,
          scale: 1,
        });
        break;
      default: return;
    }
  };

  const showSearch = () => {
    page.toggleSearch();
    setsearchResults(null);
    elSearchInput.current.value = "";
    TweenMax.set([document.querySelector(`.${sh.header}`), document.querySelector(`.${sh.scrollable}`)], { delay: .2, width: "110%" }); // temp fix for overflow: hidden & position fixed - search not showing well on mobile - TODO figure out better way later
    TweenMax.set(elSearch.current, { x: "-100%" });
    TweenMax.set(elSearchCover.current, { x: "0%" });
    TweenMax.to(elSearch.current, .3, {
      ease: Power4.easeIn,
      x: "0%",
      onComplete: () => {
        TweenMax.set("html", { overflow: "hidden" });
        TweenMax.to(elSearchCover.current, .3, {
          ease: Power4.easeInOut,
          x: "100%",
        });
        // reveal x button
        TweenMax.to(elSearchClose.current, 2, { clearProps: "all", delay: .1, ease: Power4.easeOut, opacity: 1, rotation: 0 });
        TweenMax.to(elSearchClose.current.firstElementChild.childNodes[0], .5, { clearProps: "all", delay: .3, ease: Power4.easeOut, rotation: 0, scaleY: 1 });
        TweenMax.to(elSearchClose.current.firstElementChild.childNodes[1], .5, { clearProps: "all", delay: .4, ease: Power4.easeOut, rotation: 0, scaleX: 1 });
        TweenMax.to(elSearchClose.current.firstElementChild.childNodes[3], .75, { clearProps: "all", delay: .5, ease: Power4.easeOut, rotation: 0, scaleY: 1 });
        TweenMax.to(elSearchClose.current.firstElementChild.childNodes[2], 1, { clearProps: "all", delay: .6, ease: Power4.easeOut, rotation: 0, scaleX: 1 });
      },
      onStart: () => {
        TweenMax.set(elSearchClose.current, { rotation: -120, scale: 1 });
        TweenMax.set([elSearchClose.current.firstElementChild.childNodes[0], elSearchClose.current.firstElementChild.childNodes[3]], { rotation: -45, scaleY: 0 });
        TweenMax.set([elSearchClose.current.firstElementChild.childNodes[1], elSearchClose.current.firstElementChild.childNodes[2]], { rotation: -45, scaleX: 0 });
      }
    });
  };

  return (
    <>
      <ul className={s.widgets}>
        <li onClick={!page.isFooterExpanded ? page.toggleUi : undefined}>
          <MinimizeIcon />
        </li>
        <li onClick={() => page.toggleColorTheme(page.isColorTheme === "light" ? "dark" : "light")}>
          <ThemeIcon className={s.theme} />
        </li>
        <li onClick={showSearch}>
          <SearchIcon />
        </li>
        {isSortingEnabled && (
          <li>
            <SortIcon />
          </li>
        )}
        <li className={s.i} onClick={() => {
          // TODO - find a better way to target the button
          document.querySelector("footer > div:first-of-type > span").click();
        }}>
          i
        </li>
      </ul>
      {page.isSearchExpanded && <SEO title="Search" />}
      <div className={classNames(s.search, { [s.search____visible]: page.isSearchExpanded })} ref={elSearch}>
        <button className={s.search__close} onClick={hideSearch} onMouseEnter={hoverSearchClose} onMouseLeave={hoverSearchClose} ref={elSearchClose}>
          <div>
            <div />
            <div />
            <div />
            <div />
          </div>
          <span>Close</span>
        </button>
        <div className={s.search__content} ref={elSearchContent}>
          <div className={s.search__input}>
            <input
              onKeyUp={(event) => {
                setsearchResults(event.target.value.length >= 2 ? fuzzySearch.search(event.target.value) : null);
                if (searchResults && searchResults.length > 0) elSearchContent.current.scrollTo(0, 0);
              }}
              ref={elSearchInput}
              type="text"
            />
            <div className={s.search__count}>
              {searchResults && searchResults.length > 0 ? (
                <>Found <span>{searchResults.length}</span> results.</>
              ) : Array.isArray(searchResults) ? (
                <>Nothing found. <i>😔</i></>
              ) : (
                <>Looking for what exactly?</>
              )}
            </div>
          </div>
          {searchResults && (
            <>
              <ul className={s.search__results}>
                {searchResults.map((item, index) => (
                  <li key={`search-result-${index}`} className={item.item.hidden && s.hidden_search_result}>
                    <Link onClick={hideSearch} to={`/${item.item.id}/`}>
                      <span className={s.search__results_url}>://duglegur.is/{item.item.id}/</span>
                      <h2>{item.item.name}</h2>
                      <div className={s.search__results_description} dangerouslySetInnerHTML={{ __html: item.item.description }} />
                      <span className={s.search__results_date}>{item.item.date}</span>
                      <span className={s.search__results_accuracy}>This search result is {Math.round((1 - item.score) * 100)}% relevant.</span>
                    </Link>
                  </li>
                ))}
              </ul>
              {searchResults.length > 0 && <span className={s.search__results_credits}>Search powered by <a href="https://fusejs.io/" target="_blank" rel="noopener noreferrer">fusejs.io</a>. <i>🤟</i></span>}
            </>
          )}
        </div>
        <div className={s.search__cover} ref={elSearchCover} />
      </div>
    </>
  );
};

export default Widgets;
