
import React, { useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import { Power4, TweenMax } from "gsap";

import { Navigation, PageContext, Widgets } from "..";

import s from "./Header.module.scss";

const Header = () => {

  const elScrollable = useRef(null);
  const elScrollableScroll = useRef(null);
  const page = useContext(PageContext);
  const smoothScrollEnabled = false;

  // handling header/navigation scrollable content
  useEffect(() => {
    if (!smoothScrollEnabled) return () => {};

    const changeOrder = (event) => {
      const y = -(elScrollableScroll.current.firstElementChild.getBoundingClientRect().top - elScrollable.current.parentElement.getBoundingClientRect().top);
      const yMax = Math.floor(elScrollable.current.getBoundingClientRect().height - elScrollable.current.parentElement.getBoundingClientRect().height);

      TweenMax.set(elScrollableScroll.current, {
        zIndex: event ? 3 : 1,
      });

      setTimeout(() => {
        if (y <= 0 || y >= yMax) {
          TweenMax.set(elScrollableScroll.current, {
            zIndex: 1,
          });
        }
      }, 60);
    };

    const handleResize = () => {
      TweenMax.set(elScrollableScroll.current.firstElementChild, {
        height: elScrollable.current.offsetHeight,
      });
    };

    const handleScroll = () => {
      const y = elScrollableScroll.current.firstElementChild.getBoundingClientRect().top - elScrollable.current.parentElement.getBoundingClientRect().top;

      TweenMax.to(elScrollable.current, 1.2, {
        ease: Power4.easeOut,
        onComplete: changeOrder,
        y,
      });

      setTimeout(changeOrder, 600);
    };

    handleResize();
    setTimeout(handleResize, 500); // run again after info gets expanded
    elScrollableScroll.current.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    elScrollable.current.addEventListener("DOMMouseScroll", changeOrder);
    elScrollable.current.addEventListener("mousewheel", changeOrder);
    elScrollable.current.addEventListener("touchmove", changeOrder);

    return () => {
      elScrollable.current.removeEventListener("DOMMouseScroll", changeOrder);
      elScrollable.current.removeEventListener("mousewheel", changeOrder);
      elScrollable.current.removeEventListener("touchmove", changeOrder);
      elScrollableScroll.current.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    }
  }, [page.isCurrentRoute]);
  
  return (
    <header className={classNames(s.header, { [s.header____smoothscroll_disabled]: !smoothScrollEnabled})}>
      <div className={s.scrollable} ref={elScrollable}>
        <Navigation />
      </div>
      <div className={s.scrollable__scroll} ref={elScrollableScroll}>
        <div />
      </div>
      <Widgets />
    </header>
  );  
};

export default Header;
